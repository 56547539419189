<template>
  <section class="home-slider">
    <b-row>
      <b-col lg="12">
        <home-slider></home-slider>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import HomeSlider from "@/base/core/components/home/HomeSlider.vue";

export default{
  components:{
      HomeSlider
  }
}
</script>

<style lang="scss" scoped>
</style>