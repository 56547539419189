<template>
  <div class="home-componet-wrap">
    <!-- home slider -->
    <at-home-banner></at-home-banner>

    <!-- catagories -->
    <section class="home-category-sec">
      <!-- {{ category }} -->
      <div class="container">
        <div class="row">
          <div class="col col-md-12">
            <div class="category-slider">
              <div v-if="typeof images == 'object'">
                <at-carousel-slider :initialConf="{ slidesToShow: 3, slidesToScroll: 1 }">
                  <div v-for="image of images.slides" :key="image.id">
                    <at-categoryBox :link="image.link" :imageUrl="image.media.url"
                      :buttonText="image.buttonText"></at-categoryBox>
                  </div>
                </at-carousel-slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- New in Range Products -->
    <div class="home-multi-slider" v-if="newInRangeProducts.length">
      <b-container>
        <div class="section-title">
          <h2>Nieuw in het assortiment</h2>
        </div>
        <MoreProductsCarousel :products="newInRangeProducts" />
        <div class="more-link">
          <b-link @click="loadCategory(newInRangeId)">{{
            $t("view_all_1")
          }}</b-link>
        </div>
      </b-container>
    </div>

    <!-- strip  -->
    <at-home-info-stripe></at-home-info-stripe>

    <!-- In the Action Products -->
    <div class="home-multi-slider" v-if="inActionProducts.length">
      <b-container>
        <div class="section-title">
          <h2>In de actie</h2>
        </div>
        <MoreProductsCarousel :products="inActionProducts" />
        <div class="more-link">
          <b-link @click="loadCategory(inActionId)">{{
            $t("view_all_2")
          }}</b-link>
        </div>
      </b-container>
    </div>

    <!-- cta -->
    <at-call-to-action v-if="stories && stories.length" :urlKey="stories.url_key" :baseImage="stories.base_image"
      :heading="stories.name" :description="stories.description"></at-call-to-action>

    <!-- Best Sellers -->
    <div class="home-multi-slider" v-if="bestSellersProducts.length">
      <b-container>
        <div class="section-title">
          <h2>Best sellers</h2>
        </div>
        <MoreProductsCarousel :products="bestSellersProducts" />
        <div class="more-link">
          <b-link @click="loadCategory(bestSellerId)">{{
            $t("view_all_3")
          }}</b-link>
        </div>
      </b-container>
    </div>

    <!-- blog -->

    <section class="blog-sec home" v-if="blogs && blogs.length > 0">
      <div class="container">
        <div class="row">
          <div class="col col-md-12">
            <at-carousel-slider :initialConf="{ slidesToShow: 3, slidesToScroll: 1 }">
              <div v-for="blog in blogs" :key="blog.post_id">
                <at-blog-box :isHome="true" :featuredImage="blog.featured_image" :title="blog.title"
                  :shortFilteredContent="blog.short_filtered_content" :identifier="blog.identifier"></at-blog-box>
              </div>
            </at-carousel-slider>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-12">
            <div class="blog-read-more text-right">
              <b-link :to="{ name: 'blogs' }" class="regular-15">
                <u>{{ $t("view_all_blog") }}</u>
              </b-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <CompareButton></CompareButton> -->
  </div>
</template>

<script>
import AtHomeBanner from "@/esf_antarctica_lid/core/components/home/HomeBanner.vue";
import AtHomeInfoStripe from "@/esf_antarctica_lid/core/components/home/HomeInfoStripe.vue";
import AtCallToAction from "@/base/core/components/home/CallToAction.vue";
import AtCarouselSlider from "@/esf_antarctica_lid/core/components/wrappers/CarouselSlider.vue";
import AtCategoryBox from "@/base/core/components/category/CategoryBox.vue";
import AtBlogBox from "@/base/core/components/blog/BlogBox.vue";
import MoreProductsCarousel from "@/esf_antarctica_lid/core/components/product-detail/MoreProductsCarousel.vue";
// import CompareButton from "@/base/core/components/common/CompareButton.vue";
import { loadCategoryById } from "@storefront/core/data-resolver/categories";
import { Logger } from "@storefront/core/lib/logger";
import config from "@config";

export default {
  name: "Home",
  components: {
    AtHomeBanner,
    AtHomeInfoStripe,
    AtCallToAction,
    AtCarouselSlider,
    AtCategoryBox,
    AtBlogBox,
    MoreProductsCarousel,
    // CompareButton,
  },
  data: () => ({
    newInRangeId: config.categories.new_in_range,
    inActionId: config.categories.in_action,
    bestSellerId: config.categories.best_seller,
  }),
  computed: {
    images() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "home_gallery"
      );
    },

    stories() {
      const data = this.$store.getters["stores/getStores"];
      const random = Math.floor(Math.random() * data.length);
      return data[random];
    },

    blogs() {
      let data = [...this.$store.getters["blog/getCurrentPage"]];
      const shuffled = data.sort(() => 0.5 - Math.random());

      return shuffled.slice(0, 3);
    },

    newInRangeProducts() {
      return this.$store.getters["home/getNewInRangeProducts"];
    },

    inActionProducts() {
      return this.$store.getters["home/getInActionProducts"];
    },

    bestSellersProducts() {
      return this.$store.getters["home/getBestSellers"];
    },
  },
  methods: {
    async loadCategory(categoryId) {
      const category = await loadCategoryById(categoryId).catch((e) => {
        Logger.error("loadCategoryById", "category store actions load", e)();
        throw e;
      });
      this.$router.push("/" + category.url_key);
    },
    filterInstock(products) {
      return products.filter((product) => product.stock_status === "IN_STOCK");
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_title
            : "",
        },
        {
          name: "keywords",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_keywords
            : "",
        },
        {
          name: "description",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_description
            : "",
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Home",
      // all titles will be injected into this template
      link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
    };
  },
};
</script>

<style></style>
