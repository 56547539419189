<template>
  <ClientOnly>
    <VueSlickCarousel v-bind="carouselConf">
      <slot></slot>
    </VueSlickCarousel>
  </ClientOnly>
</template>

<script>
import ClientOnly from "vue-client-only";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: { VueSlickCarousel, ClientOnly },
  props: {
    initialConf: {
      type: Object,
      require: true,
    },
    tabConf: {
      type: Object,
      default: () => {
        return { slidesToShow: 3, slidesToScroll: 1 };
      },
    },
    mobileConf: {
      type: Object,
      default: () => {
        return { slidesToShow: 1, slidesToScroll: 1 };
      },
    },
  },

  data() {
    return {
      carouselConf: {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: this.initialConf.slidesToShow,
        slidesToScroll: this.initialConf.slidesToScroll,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: this.tabConf.slidesToShow,
              slidesToScroll: this.tabConf.slidesToScroll,
            },
          },
          {
            breakpoint: 767,
            settings: {
              arrows: true,
              slidesToShow: this.mobileConf.slidesToShow,
              slidesToScroll: this.mobileConf.slidesToScroll,
            },
          },
        ],
      },
    };
  },
};
</script>

<style>
</style>