<template>
  <section class="home-stripe-sec">
    <div class="container">
      <div class="row">
        <div class="col col-12">
          <div class="home-stripe-wrapper">
            <v-runtime-template v-if="getCmsData.content"
              :template="`<div class='cms-content'>${getCmsData.content}</div>`"></v-runtime-template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template";
// import ClientOnly from "vue-client-only";
// import VueSlickCarousel from "vue-slick-carousel";
// import "vue-slick-carousel/dist/vue-slick-carousel.css";
// import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: { /*VueSlickCarousel, ClientOnly*/ VRuntimeTemplate },

  // data() {
  //   return {
  //     carouselConf: {
  //       dots: false,
  //       infinite: true,
  //       arrows: false,
  //       speed: 1300,
  //       slidesToShow: 4,
  //       slidesToScroll: 1,

  //       responsive: [
  //         {
  //           breakpoint: 1030,
  //           settings: {
  //             autoplay: true,
  //             fade: true,
  //             slidesToShow: 3,
  //             slidesToScroll: 1,
  //           },
  //         },
  //         {
  //           breakpoint: 600,
  //           settings: {
  //             autoplay: true,
  //             fade: true,
  //             slidesToShow: 2,
  //             slidesToScroll: 1,
  //           },
  //         },
  //         {
  //           breakpoint: 480,
  //           settings: {
  //             speed: 2500,
  //             autoplay: true,
  //             autoplaySpeed: 0,
  //             cssEase: "linear",
  //             slidesToShow: 1,
  //             infinite: true,
  //             arrows: false,
  //           },
  //         },
  //       ],
  //     },
  //   };
  // },
  computed: {
    HomeStripeSlider() {
      const data =
        this.$store.getters["sliders/getSliderByIdentifier"](
          "home_stripe_slider"
        );
      return data;
    },

    getCmsData() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "home_content"
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>