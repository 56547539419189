<template>
  <section class="call-to-action">
    <div class="container">
      <div class="row">
        <div class="col col-md-7">
          <div class="cta-content">
            <h2 class="semiBold-22 desktop">{{ heading }}</h2>
            <p class="regular-15">
              {{ cleaneDescription }}
            </p>
            <b-link :to="`/winkel/` + urlKey">
              <button class="c-btn c-btn-secondary">{{$t ("read_more")}}</button>
            </b-link>
          </div>
        </div>
        <div class="col col-md-5">
          <h2 class="semiBold-22 mobile">{{ heading }}</h2>
          <div class="cta-img">
            <img v-if="baseImage != null" :src="baseImage.url" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    urlKey: {
      require: true,
      type: String,
    },
    baseImage: {
      require: true,
      type: Object,
    },
    heading: {
      require: true,
      type: String,
    },
    description: {
      require: true,
      type: String,
    },
  },

  computed: {
    cleaneDescription() {
      if (!this.description) {
        return "";
      }
      return (
        this.description.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 450) +
        " ..."
      );
    },
  },
};
</script>

<style>
</style>