<template>
  <div :class="{ col: !isHome, 'col-md-4': !isHome }">
    <div class="blog-item">
      <div class="blog-img">
        <img :src="featuredImage" alt="" draggable="false" />
      </div>
      <div class="blog-content">
        <h3 class="blog-item-title regular-18">{{ title }}</h3>
        <p class="blog-item-desc regular-12">
          {{ cleaneDescription }}
        </p>
        <button
          @click="$router.push('/blog/' + identifier)"
          class="c-btn c-btn-primary"
        >
          {{$t ("read_more")}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isHome: {
      default: false,
    },
    featuredImage: {
      require: true,
      type: String,
    },
    title: {
      require: true,
      type: String,
    },
    shortFilteredContent: {
      require: true,
      type: String,
    },
    identifier: {
      require: true,
      type: String,
    },
  },

  computed: {
    cleaneDescription() {
      if (!this.shortFilteredContent) {
        return "";
      }
      return (
        this.shortFilteredContent
          .replace(/<\/?[^>]+(>|$)/g, "")
          .substring(0, 450) + " ..."
      );
    },
  },
};
</script>

<style>
</style>