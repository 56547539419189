<template>
  <ClientOnly>
    <VueSlickCarousel v-bind="carouselConf">
      <div
        class="home-slider-item"
        v-for="(bSlide, index) in slider.slides"
        :key="index"
      >
        <router-link :to="bSlide.link">
          <img :src="bSlide.optImage" class="home-slider-img" alt="" />
          <div class="container">
            <div class="row">
              <div class="col col-md-12">
                <div class="home-slider-content">
                  <h2 class="semiBold-30">{{ bSlide.title }}</h2>
                  <div class="home-slider-info">
                    <v-runtime-template
                      v-if="bSlide.description != ''"
                      :template="bSlide.description"
                    ></v-runtime-template>
                  </div>
                  <button class="c-btn c-btn-secondary">
                    {{ bSlide.buttonText }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </VueSlickCarousel>
  </ClientOnly>
</template>

<script>
import ClientOnly from "vue-client-only";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import VRuntimeTemplate from "v-runtime-template";
import { isMobile } from "mobile-device-detect";
import { isTablet } from "mobile-device-detect";
import { isBrowser } from "mobile-device-detect";
import { Logger } from "@storefront/core/lib/logger";

export default {
  components: { VueSlickCarousel, VRuntimeTemplate, ClientOnly },

  data() {
    return {
      slide: 0,
      sliding: false,
      carouselConf: {
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        infinite: true,
        autoplay: false,
        responsive: [
          {
            settings: {
              dots: false,
              infinite: true,
              speed: 1300,
              breakpoint: 600,
              autoplay: true,
              fade: true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              dots: false,
              infinite: true,
              speed: 1300,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  computed: {
    slider() {
      let slider = this.$store.getters["sliders/getSliderByIdentifier"]("home");

      if (slider.slides != null) {
        slider.slides.forEach((element) => {
          if (this.mobileCheck == "browser") {
            element.optImage = element.media.url;
          } else if (this.mobileCheck == "tablet") {
            element.optImage = element.media.smaller;
          } else {
            element.optImage = element.media.smallest;
          }
        });
      }

      return slider;
    },
    mobileCheck() {
      if (isBrowser) {
        return "browser";
      }
      if (isMobile && isTablet) {
        return "tablet";
      }
      return "mobile";
    },
  },
  mounted() {
    Logger.debug("slides", "slider", this.slider)();
    Logger.debug("isTablet", "slider", isTablet)();
    Logger.debug("isBrowser", "slider", isBrowser)();
  },

  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<style>
</style>