<template>
  <div class="category-box">
    <img :src="imageUrl" :alt="title" />
    <b-link :to="link">
      <label class="regular-18">{{ buttonText }}</label>
    </b-link>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      required: true,
      type: String,
    },
    imageUrl: {
      required: true,
      type: String,
    },
    buttonText: {
      required: true,
      type: String,
    },
    title: {
      required: false,
      type: String,
    },
  },
};
</script>

<style>
</style>
